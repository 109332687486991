<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-subtitle class="white--text secondary">
          for driver: <strong>{{ selectedItem.driverText }}</strong
          >, date: <strong>{{ formattedDate }}</strong> at <span class="success--text">{{ selectedItem.time }}</span>
        </v-card-subtitle>

        <v-card-text>
          <v-container>
            <validation-observer ref="observer">
              <v-row>
                <v-col cols="12" sm="4">
                  <data-selector
                    label="Type"
                    database-module="hosTypes"
                    getter="filtered"
                    item-text="typeText"
                    item-value="id"
                    :filter-params="filterParams"
                    :data.sync="item.typeId"
                  />
                </v-col>
                <v-col v-if="item.typeId === 4" cols="12" sm="5">
                  <validation-provider v-slot="{ errors }" name="Split time" rules="required">
                    <m-digital-time-picker v-model="item.splitTime" label="at (CST)" :error-message="errors" />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="item.typeId === 1">
                <v-col cols="12" sm="6">
                  <validation-provider v-slot="{ errors }" name="Ready time" rules="required">
                    <m-digital-time-picker v-model="item.readyTime" label="At (CST)" :error-message="errors" />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="item.typeId === 2 || item.typeId === 4">
                <v-col cols="12" sm="6">
                  <validation-provider v-slot="{ errors }" name="Driving hours" rules="required">
                    <m-digital-time-picker
                      v-model="item.hasTime"
                      label="Driving hours"
                      :hours-picker="true"
                      :error-message="errors"
                      :range="12"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider v-slot="{ errors }" name="Shift ends" rules="required">
                    <m-digital-time-picker v-model="item.hasEnds" label="Shift ends(CST)" :error-message="errors" />
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-if="hasExtra">
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-switch v-model="item.isCycle" label="Cycle left" class="d-inline-block"></v-switch>
                  </v-col>
                  <v-col v-if="item.isCycle" cols="12" sm="4">
                    <validation-provider v-slot="{ errors }" name="Cycles left" rules="required">
                      <m-digital-time-picker
                        v-model="item.cyclesLeft"
                        label="Hours"
                        :hours-picker="true"
                        :error-message="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-switch
                      v-model="item.isRecap"
                      label="Recap"
                      class="d-inline-block"
                      :disabled="item.isNoRecap"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="item.isRecap" cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tomorrow"
                      :rules="item.hasRecapTmrw ? 'required' : ''"
                    >
                      <m-digital-time-picker
                        v-model="item.recapTmrw"
                        label="Tomorrow"
                        :hours-picker="true"
                        :error-message="errors"
                        :disabled="noRecapTmrw"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col v-if="item.isRecap" cols="12" sm="5">
                    <v-checkbox v-model="noRecapTmrw" label="No recap" class="d-inline-block"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="item.isRecap">
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Day after"
                      :rules="item.hasRecapAfter ? 'required' : ''"
                    >
                      <m-digital-time-picker
                        v-model="item.recapAfter"
                        label="Day after"
                        :hours-picker="true"
                        :error-message="errors"
                        :disabled="noRecapAfter"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-checkbox v-model="noRecapAfter" label="No recap" class="d-inline-block"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="item.isRecap">
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="item.recapOnDay"
                      :items="weekDays"
                      label="Recap on day"
                      :disabled="noRecapOnDay"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="On day"
                      :rules="item.hasRecapOnDay ? 'required' : ''"
                    >
                      <m-digital-time-picker
                        v-model="item.recapDayValue"
                        :hours-picker="true"
                        :error-message="errors"
                        :disabled="noRecapOnDay"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-checkbox v-model="noRecapOnDay" label="No recap" class="d-inline-block"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-switch
                      v-model="item.isNoRecap"
                      label="No Recap"
                      class="d-inline-block"
                      :disabled="item.isRecap"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="item.isNoRecap" cols="12" sm="5">
                    <validation-provider v-slot="{ errors }" name="Until date" rules="required">
                      <m-date-picker
                        v-model="item.noRecapDate"
                        label="Until"
                        :week-day="true"
                        :error-message="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="item.typeId">
                <v-col cols="12">
                  <v-textarea v-model.trim="item.note" auto-grow label="Note" rows="4" row-height="30" clearable>
                  </v-textarea>
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-divider />
        <edit-actions :disabled-submit="true" @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'
import DataSelector from './_DataSelector.vue'
import crudEdit from '../../mixins/crudEdit'
import MDigitalTimePicker from '../MDigitalTimePicker.vue'
import MDatePicker from '../MDatePicker.vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    EditHeader,
    EditActions,
    DataSelector,
    MDigitalTimePicker,
    MDatePicker,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [crudEdit],
  props: ['visible', 'selectedItem', 'dialogTitle', 'formattedDate', 'databaseModule'],
  data() {
    return {
      defaultItem: {
        id: '',
        time: '',
        date: '',
        driverId: '',
        driverText: '',
        readyTime: '',
        hasTime: '',
        hasEnds: '',
        note: '',
        cyclesLeft: '',
        recapTmrw: '',
        recapAfter: '',
        noRecapDate: '',
        typeId: '',
        isCycle: false,
        isRecap: false,
        isNoRecap: false,
        hasRecapTmrw: false,
        hasRecapAfter: false,
        hasRecapOnDay: false,
        recapOnDay: '',
        recapDayValue: '',
        splitTime: '',
      },
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    }
  },
  computed: {
    filterParams() {
      let split = this.selectedItem.time === 'Split'
      return { split: split }
    },
    hasExtra() {
      return this.item.typeId === 1 || this.item.typeId === 2
    },
    prefixDate() {
      let date = this.item.date.substring(0, 10)
      const [year, month, day] = date.split('-')
      return {
        year: year,
        month: month - 1,
        day: day,
      }
    },
    noRecapTmrw: {
      get() {
        return !this.item.hasRecapTmrw
      },
      set(value) {
        this.item.hasRecapTmrw = !value
      },
    },
    noRecapAfter: {
      get() {
        return !this.item.hasRecapAfter
      },
      set(value) {
        this.item.hasRecapAfter = !value
      },
    },
    noRecapOnDay: {
      get() {
        return !this.item.hasRecapOnDay
      },
      set(value) {
        this.item.hasRecapOnDay = !value
      },
    },
  },
  methods: {
    addHeaders(tempItem, item) {
      let headers = ['id', 'date', 'time', 'driverId', 'driverText', 'typeId']
      headers.forEach((value) => {
        tempItem[value] = item[value]
      })
    },
    submitFormatter(item) {
      // id: 1
      // date: "2021-11-08"
      // time: "8 AM"
      // driverId: 1
      // driverText: "Tomas Garcia TWIC*"
      // typeId: 1
      // readyTime: "2022-01-31T08:00:00.000Z"
      // hasEnds: ""
      // hasTime: ""
      // note: ""
      // isCycle: false
      // cyclesLeft: ""
      // isRecap: false
      // recapAfter: ""
      // recapTmrw: ""
      // isNoRecap: true
      // noRecap: "2021-11-10T00:00:00.000Z"
      let tempItem = Object.assign({}, this.defaultItem)
      this.addHeaders(tempItem, item)
      if (item.typeId) {
        tempItem.note = item.note
      }

      if (item.typeId === 1) {
        const [hours, minutes] = item.readyTime.split(':')
        const d = this.prefixDate
        tempItem.readyTime = new Date(Date.UTC(d.year, d.month, d.day, hours, minutes)).toISOString()
      }
      if (item.typeId === 2 || item.typeId === 4) {
        const [hours, minutes] = item.hasEnds.split(':')
        const d = this.prefixDate
        tempItem.hasEnds = new Date(Date.UTC(d.year, d.month, d.day, hours, minutes)).toISOString()

        tempItem.hasTime = item.hasTime
      }
      if (item.typeId === 4) {
        const [hours, minutes] = item.splitTime.split(':')
        const d = this.prefixDate
        tempItem.splitTime = new Date(Date.UTC(d.year, d.month, d.day, hours, minutes)).toISOString()
      }
      if (item.isCycle) {
        tempItem.isCycle = true
        tempItem.cyclesLeft = item.cyclesLeft
      }
      if (item.isRecap) {
        tempItem.isRecap = true
        tempItem.hasRecapTmrw = item.hasRecapTmrw
        tempItem.recapTmrw = item.recapTmrw
        tempItem.hasRecapAfter = item.hasRecapAfter
        tempItem.recapAfter = item.recapAfter
        tempItem.hasRecapOnDay = item.hasRecapOnDay
        tempItem.recapOnDay = item.recapOnDay
        tempItem.recapDayValue = item.recapDayValue
      }
      if (item.isNoRecap) {
        tempItem.isNoRecap = true
        tempItem.noRecapDate = item.noRecapDate
      }

      // item = Object.assign({}, tempItem)
      Object.keys(tempItem).forEach((key) => {
        item[key] = tempItem[key]
      })
    },
    updateFormatter(item) {
      if (item.readyTime) item.readyTime = item.readyTime.substring(11, 16)
      if (item.hasEnds) item.hasEnds = item.hasEnds.substring(11, 16)
      if (item.splitTime) item.splitTime = item.splitTime.substring(11, 16)
    },
  },
}
</script>
