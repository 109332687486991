import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c('edit-header',{attrs:{"dialog-title":_vm.dialogTitle}}),_c(VCardSubtitle,{staticClass:"white--text secondary"},[_vm._v(" for driver: "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.driverText))]),_vm._v(", date: "),_c('strong',[_vm._v(_vm._s(_vm.formattedDate))]),_vm._v(" at "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.selectedItem.time))])]),_c(VCardText,[_c(VContainer,[_c('validation-observer',{ref:"observer"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('data-selector',{attrs:{"label":"Type","database-module":"hosTypes","getter":"filtered","item-text":"typeText","item-value":"id","filter-params":_vm.filterParams,"data":_vm.item.typeId},on:{"update:data":function($event){return _vm.$set(_vm.item, "typeId", $event)}}})],1),(_vm.item.typeId === 4)?_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":"Split time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"at (CST)","error-message":errors},model:{value:(_vm.item.splitTime),callback:function ($$v) {_vm.$set(_vm.item, "splitTime", $$v)},expression:"item.splitTime"}})]}}],null,false,4217650486)})],1):_vm._e()],1),(_vm.item.typeId === 1)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Ready time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"At (CST)","error-message":errors},model:{value:(_vm.item.readyTime),callback:function ($$v) {_vm.$set(_vm.item, "readyTime", $$v)},expression:"item.readyTime"}})]}}],null,false,3632217583)})],1)],1):_vm._e(),(_vm.item.typeId === 2 || _vm.item.typeId === 4)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Driving hours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"Driving hours","hours-picker":true,"error-message":errors,"range":12},model:{value:(_vm.item.hasTime),callback:function ($$v) {_vm.$set(_vm.item, "hasTime", $$v)},expression:"item.hasTime"}})]}}],null,false,1836544102)})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Shift ends","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"Shift ends(CST)","error-message":errors},model:{value:(_vm.item.hasEnds),callback:function ($$v) {_vm.$set(_vm.item, "hasEnds", $$v)},expression:"item.hasEnds"}})]}}],null,false,401049630)})],1)],1):_vm._e(),(_vm.hasExtra)?_c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VSwitch,{staticClass:"d-inline-block",attrs:{"label":"Cycle left"},model:{value:(_vm.item.isCycle),callback:function ($$v) {_vm.$set(_vm.item, "isCycle", $$v)},expression:"item.isCycle"}})],1),(_vm.item.isCycle)?_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Cycles left","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"Hours","hours-picker":true,"error-message":errors},model:{value:(_vm.item.cyclesLeft),callback:function ($$v) {_vm.$set(_vm.item, "cyclesLeft", $$v)},expression:"item.cyclesLeft"}})]}}],null,false,95855538)})],1):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VSwitch,{staticClass:"d-inline-block",attrs:{"label":"Recap","disabled":_vm.item.isNoRecap},model:{value:(_vm.item.isRecap),callback:function ($$v) {_vm.$set(_vm.item, "isRecap", $$v)},expression:"item.isRecap"}})],1),(_vm.item.isRecap)?_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Tomorrow","rules":_vm.item.hasRecapTmrw ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"Tomorrow","hours-picker":true,"error-message":errors,"disabled":_vm.noRecapTmrw},model:{value:(_vm.item.recapTmrw),callback:function ($$v) {_vm.$set(_vm.item, "recapTmrw", $$v)},expression:"item.recapTmrw"}})]}}],null,false,808426623)})],1):_vm._e(),(_vm.item.isRecap)?_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"label":"No recap"},model:{value:(_vm.noRecapTmrw),callback:function ($$v) {_vm.noRecapTmrw=$$v},expression:"noRecapTmrw"}})],1):_vm._e()],1),(_vm.item.isRecap)?_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Day after","rules":_vm.item.hasRecapAfter ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"label":"Day after","hours-picker":true,"error-message":errors,"disabled":_vm.noRecapAfter},model:{value:(_vm.item.recapAfter),callback:function ($$v) {_vm.$set(_vm.item, "recapAfter", $$v)},expression:"item.recapAfter"}})]}}],null,false,1361381894)})],1),_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"label":"No recap"},model:{value:(_vm.noRecapAfter),callback:function ($$v) {_vm.noRecapAfter=$$v},expression:"noRecapAfter"}})],1)],1):_vm._e(),(_vm.item.isRecap)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"items":_vm.weekDays,"label":"Recap on day","disabled":_vm.noRecapOnDay},model:{value:(_vm.item.recapOnDay),callback:function ($$v) {_vm.$set(_vm.item, "recapOnDay", $$v)},expression:"item.recapOnDay"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"On day","rules":_vm.item.hasRecapOnDay ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-digital-time-picker',{attrs:{"hours-picker":true,"error-message":errors,"disabled":_vm.noRecapOnDay},model:{value:(_vm.item.recapDayValue),callback:function ($$v) {_vm.$set(_vm.item, "recapDayValue", $$v)},expression:"item.recapDayValue"}})]}}],null,false,1581431300)})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"label":"No recap"},model:{value:(_vm.noRecapOnDay),callback:function ($$v) {_vm.noRecapOnDay=$$v},expression:"noRecapOnDay"}})],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VSwitch,{staticClass:"d-inline-block",attrs:{"label":"No Recap","disabled":_vm.item.isRecap},model:{value:(_vm.item.isNoRecap),callback:function ($$v) {_vm.$set(_vm.item, "isNoRecap", $$v)},expression:"item.isNoRecap"}})],1),(_vm.item.isNoRecap)?_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c('validation-provider',{attrs:{"name":"Until date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-date-picker',{attrs:{"label":"Until","week-day":true,"error-message":errors},model:{value:(_vm.item.noRecapDate),callback:function ($$v) {_vm.$set(_vm.item, "noRecapDate", $$v)},expression:"item.noRecapDate"}})]}}],null,false,807692992)})],1):_vm._e()],1)],1):_vm._e(),(_vm.item.typeId)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"auto-grow":"","label":"Note","rows":"4","row-height":"30","clearable":""},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})],1)],1):_vm._e()],1)],1)],1),_c(VDivider),_c('edit-actions',{attrs:{"disabled-submit":true},on:{"exit":_vm.exit,"submit":_vm.onSubmit}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }