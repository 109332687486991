<template>
  <v-menu
    ref="menu"
    v-model="menuDate"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :disabled="disabled"
        :error-messages="errorMessage"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      :active-picker.sync="activePicker"
      no-title
      :first-day-of-week="1"
      :min="minDate ? minDate : undefined"
      :max="maxDate ? maxDate : undefined"
    />
  </v-menu>
</template>

<script>
import dateF from '../services/date.service'
// min="1950-01-01"
// :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)"
export default {
  props: [
    'value',
    'label',
    'disabled',
    'errorMessage',
    /********************************************
     * true - ide godina, pa mesec, pa dan
     ********************************************/
    'birthday',
    'minDate',
    'maxDate',
    'minWidth',
    'maxWidth',
    /********************************************
     * true - prikazuje u formatu datuma i koji je dan u nedelji
     ********************************************/
    'weekDay',
  ],
  data() {
    return {
      activePicker: null,
      menuDate: false,
      notInit: true,
      selectedDate: '',
    }
  },
  computed: {
    dateFormatted() {
      if (this.selectedDate) {
        if (this.weekDay) return dateF.formatDate(this.selectedDate)
        if (this.birthday) return dateF.formatBirthday(this.selectedDate)
        return dateF.formatWithoutDay(this.selectedDate)
      } else return ''
    },
  },
  watch: {
    menuDate(val) {
      this.birthday && val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    value: {
      immediate: true,
      handler: 'handleInput',
    },
    selectedDate(newVal) {
      this.notInit && this.saveDate(newVal)
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date)
      this.$emit('input', date)
      this.menuDate = false
    },
    handleInput(value) {
      if (!value) return
      this.notInit = false
      this.selectedDate = value.substring(0, 10)
      this.notInit = true
    },
  },
}
</script>
<style scoped>
.theme--dark.v-picker /deep/ .v-picker__body {
  background-color: var(--v-secondary-base);
}
</style>
